//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    hotelData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {},
  created () { },
  activated () { },
  watch: {},
  computed: {}
}
