//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment"

import NewChangeClause from "@/page/admin/travel-book/flight/component/newChangeClause/index.vue"

import consumer_air_ticket_flightRules from '@/lib/common-service/consumer_air_ticket_flightRules' // 获取退改规则

export default {
  props: {
    flightData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  components: {
    NewChangeClause
  },
  created () { },
  activated () { },
  watch: {},
  computed: {},
  filters: {
    filterWeek (val) {
      if (val === '') {
        return ''
      }
      let day = moment(val).day();
      if (day === 0) {
        return '周日'
      } else if (day === 1) {
        return '周一'
      } else if (day === 2) {
        return '周二'
      } else if (day === 3) {
        return '周三'
      } else if (day === 4) {
        return '周四'
      } else if (day === 5) {
        return '周五'
      } else if (day === 6) {
        return '周六'
      } else {
        return ''
      }
    }
  },
  methods: {
    showThisRuleList (index, val) {
      let orangeFlightList = JSON.parse(JSON.stringify(this.flightData.theFlightInformationList))
      orangeFlightList[index].loading = true
      this.flightData.theFlightInformationList = orangeFlightList

      let params = {
        cabinCode: val.cabinCode,
        flightNo: val.flightNo,
        dateTime: `${val.depDate} ${val.depTime}`,
        orderNo: this.flightData.orderNo,
        segmentNo: val.segmentNo
      }
      let ruleText = []
      let luggage = ''
      consumer_air_ticket_flightRules(params).then(res => {
        if (res.datas.rsData) {
          let refRule = res.datas.rsData.refRule;
          let chaRule = res.datas.rsData.chaRule;
          let refRuleTitArr = []
          let chaRuleTitArr = []
          let refRuleArr = []
          let chaRuleArr = []
          if (refRule && chaRule) {
            refRule = refRule.rules
            chaRule = chaRule.rules
            refRule.forEach(value => {
              value.type = "refRule"
              if (refRuleTitArr.indexOf(value.ruleTime) === -1) {
                refRuleTitArr.push(value.ruleTime)
                refRuleArr.push(value)
              }
            })
            chaRule.forEach(value => {
              value.type = "chaRule"
              if (chaRuleTitArr.indexOf(value.ruleTime) === -1) {
                chaRuleTitArr.push(value.ruleTime)
                chaRuleArr.push(value)
              }
            })
            refRuleArr.sort((a, b) => {
              return a.serial - b.serial
            })
            chaRuleArr.sort((a, b) => {
              return a.serial - b.serial
            })
            // refRule.map(item => (item.type = "refRule"));
            // chaRule.map(item => (item.type = "chaRule"));
            ruleText = [refRuleArr, chaRuleArr];
          }
          luggage = res.datas.rsData.luggage
        } else {
          ruleText = [];
          luggage = ''
        }

        let flightList = JSON.parse(JSON.stringify(this.flightData.theFlightInformationList))
        flightList[index].ruleText = ruleText;
        flightList[index].luggage = luggage;
        flightList[index].loading = false
        this.flightData.theFlightInformationList = flightList
      })
    }
  }
}
