//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment"

  export default {
  props: {
    exceedInfoData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      exceedInfoList: []
    }
  },
  created () { },
  activated () { },
  watch: {
    exceedInfoData: {
      immediate: true,
      handler (val) {
        if (val.trainsOrderTipsResponses) {
          this.exceedInfoList = val.trainsOrderTipsResponses
        }
        if (val.trainstrainsOrder) {
          this.exceedInfoList = val.trainstrainsOrder
        }
        if (val.hoteltrainsOrderTipsResponses) {
          this.exceedInfoList = val.hoteltrainsOrderTipsResponses
        }
      }
    }
  },
  computed: {},
  filters: {
    format_time (val) {
      return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : ""
    }
  },
  methods: {}
}
