//
//
//
//
//
//

import ExceedDetail from "@/page/admin/office/my-apply/components/exceedDetail"

import overproofDetail from "@/lib/data-service/haolv-default/consumer_overproof_detail"   // 超标单详情

export default {
  data () {
    return {
      loading: false,
      type: '',   // 类型：“applyDetail”-申请详情，“approve”-审核，“approveDetail”-审核详情   "invalidApproveDetail"
      applyId: 0,   // 申请单id
        applyNo: '',
        appType: 1,
        selectType: '',
        approveStatus: 1,
        listApproveStatus: 1,
      exceedDetail: {}
    }
  },
  components: {
    ExceedDetail
  },
  async activated () {
    this.type = this.$route.query.type
    this.applyId = parseInt(this.$route.query.applyId);
      this.applyNo = this.$route.query.applyNo || '';
      this.appType = parseInt(this.$route.query.appType);
      this.selectType = parseInt(this.$route.query.selectType) || '';
      this.approveStatus = parseInt(this.$route.query.approveStatus);
      this.listApproveStatus = parseInt(this.$route.query.listApproveStatus);
      await this.$store.state.workflowDefer.promise;
    this.getExceedDetail()
  },
  beforeRouteEnter (to, from, next) {
    to.meta.lastMenu = to.query.lastMenu
    next()
  },
  methods: {
    // 超标单详情
    async getExceedDetail () {
      try {
        this.loading = true
        const res = await overproofDetail({
          applyId: this.applyId,
            applyNo: this.applyNo,
            appType: this.appType,
            selectType: this.selectType,
            approveStatus: this.listApproveStatus
        })
        this.exceedDetail = res.datas
      } catch (error) {

      } finally {
        this.loading = false
      }
    }
  }
}
