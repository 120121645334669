import FlightInfo from "@/page/admin/office/my-apply/components/flightInfo"
import TrainInfo from "@/page/admin/office/my-apply/components/trainInfo"
import HotelInfo from "@/page/admin/office/my-apply/components/hotelInfo"
import ExceedInfo from "@/page/admin/office/my-apply/components/exceedInfo"
// import Approval from "@/page/admin/office/my-apply/components/approval"
import ApprovalProcess from "@/component/approval-process/1.0.0/index.vue"
import ApprovalProcessForWorkflow from "@/component/approval-process-for-workflow/1.0.0/index.vue"
import FlightDetail from "@/page/admin/office/my-apply/components/flightDetail"
import TrainDetail from "@/page/admin/office/my-apply/components/trainDetail"
import HotelDetail from "@/page/admin/office/my-apply/components/hotelDetail"
import ApplyInfo from "@/page/admin/office/my-apply/components/applyInfo"

import repealApply from "@/lib/data-service/haolv-default/consumer_apply_repealApply"   // 撤销申请

export default {
  data () {
    return {
      businessType: 0,   // 业务类型：1.机票，2.火车票，3.酒店
      status: 0,   // 审批状态：1.审核中，2.审批同意，3.已驳回，4.已撤销

      flightData: {},   // 机票
      trainData: {},   // 火车票
      hotelData: {},   // 酒店
      exceedInfoData: {},   // 超标信息
      approvalParams: {},   // 审批进度传参
      approvalData: [],   // 审批进度
      applyInfoData: {}   // 出差信息
    }
  },
  components: {
    FlightInfo,
    TrainInfo,
    HotelInfo,
    ExceedInfo,
    // Approval,
    ApprovalProcess,
    ApprovalProcessForWorkflow,
    FlightDetail,
    TrainDetail,
    HotelDetail,
    ApplyInfo
  },
  props: {
    type: {
      type: String,
      required: true
    },
    exceedDetail: {
      type: Object,
      required: true
    },
    getExceedDetail: {
      type: Function,
      required: true
    }
  },
  created () { },
  mounted () { },
  activated () { },
  watch: {
    // 业务类型：1.机票，2.火车票，3.酒店
    exceedDetail: {
      handler (val) {
        this.businessType = val.evectionDetailResponse.businessType;
        this.applyInfoData = val.evectionDetailResponse;
        this.status = val.status;
        this.approvalData = val.tusApproveRecords;
        this.approvalParams = {
          approveType: 2,   // approveType 审批类型：1.出差申请，2报销申请，3.超标申请，4.改签，5.退票
          applyNo: val.applyNo,   // 申请单编号
          approveFlowId: val.approveFlowId,   // 申请流水编号
          isHasFlow: val.isHasFlow,   // 是否有审批流程
          isLastNode: val.isLastNode   // 是否最后的节点
        };

        setTimeout(() => {
          if (this.$store.state.workflow === '2') {
            this.$refs.approvalProcess.init({
              status: this.status,
              statusText: val.statusText,
              approvalData: this.approvalData,
              approvalReason: val.approvalReason,
              processInstanceId: this.exceedDetail.processInstanceId,
              btnParams: {
                isShow: this.type === 'approve' ? true : false,
                approvalParams: this.approvalParams
              }
            })
          }
          if (this.$store.state.workflow === '1') {
            this.$refs.approvalProcessForWorkflow.init({
              budgetAmount: val.budgetAmount || 0,
              taskId: val.taskId,
              status: val.status,
              statusText: val.statusText,
              approvalData: this.approvalData,
              approvalReason: val.approvalReason,
              btnParams: {
                //isShow: this.type === 'approve' ? true : false,
                //approvalParams: this.approvalParams
              }
            })
          }
        }, 500)

        if (this.businessType === 1) {
          this.flightData = val.orderDetailResponse
          this.flightData.evectionApplyId = this.applyInfoData.evectionApplyId
          if (this.flightData.theFlightInformationList) {
            this.flightData.theFlightInformationList.forEach(value => {
              value.ruleText = []
              value.luggage = ''
            })
          }
          this.exceedInfoData = this.flightData
        }
        if (this.businessType === 2) {
          this.trainData = val.trainsOrderDetailResponse
          this.trainData.evectionApplyId = this.applyInfoData.evectionApplyId
          this.exceedInfoData = this.trainData
        }
        if (this.businessType === 3) {
          this.hotelData = val.todHotelOrderInterimResponse
          this.hotelData.evectionApplyId = this.applyInfoData.evectionApplyId
          this.exceedInfoData = this.hotelData
          this.hotelData.timeoutApproval = val.timeoutApproval
        }
      }
    }
  },
  computed: {},
  methods: {
    // 返回
    // 类型：“applyDetail”-申请详情，“approve”-审核，“approveDetail”
    handleBackClick () {
      if (this.type === 'applyDetail') {
        this.$router.push({
          name: 'admin-my-apply-manage-apply'
        })
        return
      }
      if (this.type === 'approve') {
        this.$router.push({
          name: 'admin-my-apply-manage-no-approve'
        })
        return
      }
      if (this.type === 'approveDetail') {
        this.$router.push({
          name: 'admin-my-apply-manage-have-approve'
        })
      }
      if (this.type === 'invalidApproveDetail') {
        this.$router.push({
          name: 'admin-my-apply-manage-invalid-approve'
        })
      }
    },
    // 撤销
    handleCancelClick () {
      this.$confirm("确定要撤销吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          repealApply({ applyNo: this.exceedDetail.applyNo }).then((res) => {
            this.$message({ type: "success", message: "撤销成功！" })
            this.getExceedDetail()
          })
        })
        .catch(() => { })
    }
  }
}
