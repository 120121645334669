//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from "moment"

export default {
  props: {
    hotelData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      orderInfo: {},
      timeoutApproval: ''
    }
  },
  created () { },
  activated () { },
  watch: {
    hotelData: {
      immediate: true,
      handler (val) {
        this.orderInfo = val.orderInterimResult
        this.timeoutApproval = val.timeoutApproval
      }
    }
  },
  filters: {
    format_time (val) {
      return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : ""
    }
  },
  methods: {
    // 订单状态
    getStatusColor(val) {
      if (val === 0 || val === 2 || val === 18) {
        return 'orange'
      } else if (val === 11) {
        return 'green'
      } else {
        return 'red'
      }
    },
    // 查看
    handleCheckClick () {
      this.$router.push({
        name: 'admin-my-apply-manage-apply-info',
        query: {
          applyId: this.hotelData.evectionApplyId,
          approveType: 1,
          type: 'info',
          lastMenu: 'adminMyApply'
        }
      })
    }
  }
}
