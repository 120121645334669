//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    trainData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  created () { },
  activated () { },
  watch: {},
  computed: {},
  methods: {
    getTime (gmtStart, gmtArrive) {
      let oldTime = new Date(gmtStart);
      let newTime = new Date(gmtArrive);

      let difftime = (newTime - oldTime) / 1000; //计算时间差,并把毫秒转换成秒

      let days = parseInt(difftime / 86400);
      let hours = parseInt(difftime / 3600) - 24 * days;
      let minutes = parseInt(difftime % 3600 / 60);

      if (days == 0) {
        return `${hours}时${minutes}分`
      } else {
        return `${days}天${hours}时${minutes}分`
      }

    },
    getWeek (data) {
      switch (data) {
        case 0:
          return '星期日';
        case 1:
          return '星期一';
        case 2:
          return '星期二';
        case 3:
          return '星期三';
        case 4:
          return '星期四';
        case 5:
          return '星期五';
        case 6:
          return '星期六';
      }
    }
  }
}
