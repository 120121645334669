//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    flightData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    // 订单状态
    getStatusColor(val) {
      //  10=预订，11=待支付，12=已支付，13=超标审批中，21=待出票，22=出票成功，23=出票失败，24=作废，90=已取消'
      if (val === 11 || val === 12 || val === 13 || val === 21) {
        return 'orange'
      } else if (val === 22) {
        return 'green'
      } else {
        return 'red'
      }
    },
    // 查看
    handleCheckClick () {
      this.$router.push({
        name: 'admin-my-apply-manage-apply-info',
        query: {
          applyId: this.flightData.evectionApplyId,
          approveType: 1,
          type: 'info',
          lastMenu: 'adminMyApply'
        }
      })
    }
  },
  created () { },
  activated () { },
  watch: {},
  computed: {}
}
